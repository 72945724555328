<template>
    <div class="modal fade createNewModal" id="CrearCampanaModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-15 p-md-40">
                <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
                    <h5 class="modal-title fw-bold text-black">Crear nuevo campaña</h5>
                </div>
                <div class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing">
                    <div class="card-body">
                        <ul class="nav nav-tabs bg-white border-0 rounded-0" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100 active"
                                    id="shipping-details-tab" data-bs-toggle="tab"
                                    data-bs-target="#shipping-details-tab-pane" type="button" role="tab"
                                    aria-controls="shipping-details-tab-pane" aria-selected="true">
                                    <i class="flaticon-idea"></i>
                                    Información Campaña
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100"
                                    id="payment-method-tab" data-bs-toggle="tab" data-bs-target="#payment-method-tab-pane"
                                    type="button" role="tab" aria-controls="payment-method-tab-pane" aria-selected="false">
                                    <i class="flaticon-brain"></i>
                                    Prompt 1
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100"
                                    id="place-order-tab" data-bs-toggle="tab" data-bs-target="#place-order-tab-pane"
                                    type="button" role="tab" aria-controls="place-order-tab-pane" aria-selected="false">
                                    <i class="flaticon-brain"></i>
                                    Prompt 2
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content p-15 p-sm-20 p-md-25 p-lg-30" id="myTabContent">
                            <div class="tab-pane fade show active" id="shipping-details-tab-pane" role="tabpanel"
                                tabindex="0">
                                <h5 class="mb-15 mb-md-25 fw-bold text-black">
                                    Ingrese la información de la campaña
                                </h5>
                                <form action="#" >
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Nombre de la campaña
                                                </label>
                                                <input type="text" class="form-control shadow-none rounded-0 text-black"
                                                    placeholder="e.g. Campaña Prueba" />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Negocio
                                                </label>
                                                <select class="form-select shadow-none fw-semibold rounded-0">
                                                    <option selected>Seleccione el negocio</option>
                                                    <option value="1">Negocio Prueba1</option>
                                                    <option value="2">Negocio Prueba2</option>
                                                    <option value="3">Negocio Prueba3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Uso
                                                </label>
                                                <select class="form-select shadow-none fw-semibold rounded-0">
                                                    <option selected>Seleccione el uso </option>
                                                    <option value="1">Ventas</option>
                                                    <option value="2">Cobranza</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Asignación
                                                </label>
                                                <select class="form-select shadow-none fw-semibold rounded-0">
                                                    <option selected>Seleccione asignación</option>
                                                    <option value="1">Manual</option>
                                                    <option value="2">Automática todos los usuarios</option>
                                                    <option value="3">Automática usuarios conectados</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <span></span>
                                    <button
                                        class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                                        type="submit">
                                        Guardar y siguiente
                                        <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="payment-method-tab-pane" role="tabpanel" tabindex="0">
                                <h5 class="mb-15 mb-md-25 fw-bold text-black">
                                    Ingrese su prompt
                                </h5>
                                <form action="#" >
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Sistema
                                                </label>
                                                <textarea cols="30" rows="9"
                                                    class="form-control shadow-none rounded-0 text-black"
                                                    placeholder="Write some note here..."></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                   General 
                                                </label>
                                                <textarea cols="30" rows="9"
                                                    class="form-control shadow-none rounded-0 text-black"
                                                    placeholder="Write some note here..."></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Especifico
                                                </label>
                                                <textarea cols="30" rows="9"
                                                    class="form-control shadow-none rounded-0 text-black"
                                                    placeholder="Write some note here..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <span></span>
                                    <button
                                        class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                                         type="submit">
                                        Guardar y siguiente
                                        <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="place-order-tab-pane" role="tabpanel" tabindex="0">
                                <h5 class="mb-15 mb-md-25 fw-bold text-black">
                                    Ingrese su prompt
                                </h5>
                                <form action="#">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Requisitos
                                                </label>
                                                <textarea cols="30" rows="9"
                                                    class="form-control shadow-none rounded-0 text-black"
                                                    placeholder="Write some note here..."></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Productos
                                                </label>
                                                <textarea cols="30" rows="9"
                                                    class="form-control shadow-none rounded-0 text-black"
                                                    placeholder="Write some note here..."></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-15 mb-sm-20 mb-md-25">
                                                <label class="d-block text-black fw-semibold mb-10">
                                                    Precios
                                                </label>
                                                <textarea cols="30" rows="9"
                                                    class="form-control shadow-none rounded-0 text-black"
                                                    placeholder="Write some note here..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <span></span>
                                    <button
                                        class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                                        type="button"  data-bs-dismiss="modal" aria-label="Close">
                                        Añadir
                                        <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "CrearUsuarioModal",
};
</script>