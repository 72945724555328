<template>
    <BreadCrumb PageTitle="CHATS - Entrantes" />
    <ChatsEntrantesContent/>
  </template>
  
  <script lang="ts">

import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../../components/Common/BreadCrumb.vue"
  import ChatsEntrantesContent from "../../../../my-components/ChatsControl/ChatsEntrantes/ChatsEntrantesContent.vue"
  
  export default defineComponent({
    name: "ConexionesPage",
    components: {
      BreadCrumb,
      ChatsEntrantesContent
    },
  });
  </script>