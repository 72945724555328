<template>
  <BreadCrumb PageTitle="CHATS - IA" />
  <ChatsIaContent />
</template>
  
<script lang="ts">

import { defineComponent } from "vue";

import BreadCrumb from "../../../../components/Common/BreadCrumb.vue"
import ChatsIaContent from "../../../../my-components/Chats/ChatsIa/ChatsIaContent.vue"

export default defineComponent({
  name: "ConexionesPage",
  components: {
    BreadCrumb,
    ChatsIaContent
  },
});
</script>