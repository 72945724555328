<template>
    <BreadCrumb PageTitle="Negocios"/>
    <NegociosContent />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
  import NegociosContent from "../../../my-components/Negocios/NegociosContent.vue"


  
  export default defineComponent({
    name: "NegociosPage",
    components: {
      BreadCrumb,
      NegociosContent
    },
  });
  </script>