<template>
  <BreadCrumb PageTitle="CHATS - Manual" />
  <ChatsManualContent />
</template>
  
<script lang="ts">

import { defineComponent } from "vue";

import BreadCrumb from "../../../../components/Common/BreadCrumb.vue"
import ChatsManualContent from "../../../../my-components/Chats/ChatsManual/ChatsManualContent.vue"

export default defineComponent({
  name: "ConexionesPage",
  components: {
    BreadCrumb,
    ChatsManualContent
  },
});
</script>