<template>
    <div class="modal fade createNewModal" id="AsignarChatModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-15 p-md-40">
                <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
                    <h5 class="modal-title fw-bold text-black">Asignar conversación</h5>
                </div>
                <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
                    <form action="#">


                        <div class="mb-mb-15 mb-md-20">
                            <label for="overviewEmail" class="form-label fw-medium">Asignar conversación a:</label>
                            <select class="form-select form-select-lg shadow-none fs-16 text-black mb-15 mb-md-20">


                                <option selected>Seleccione un usuario</option>


                                <option value="1">Juan Pérez</option>


                                <option value="2">John James </option>


                            </select>

                        </div>


                        <button  type="button"  data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary">Asignar</button>

                    </form>
                </div>
                <button type="button"  data-bs-dismiss="modal" aria-label="Close" class="btn-close shadow-none"></button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "AsignarChatModal",
};
</script>