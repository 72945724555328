<template>
    <LoginForm />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";

  import LoginForm from "../../../my-components/Authentication/Login/LoginForm.vue";
  
  export default defineComponent({
    name: "AuthView",
    components: {
      LoginForm,
    },
  });
  </script>