<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <button href="#" data-bs-toggle="modal" data-bs-target="#CrearCampanaModal" type="button"
          class="default-btn border-0 transition d-block w-100 text-center position-relative text-white fs-md-15 fs-lg-16 fw-medium pt-18 pb-18 ps-15 pe-15 d-inline-block">
          Añadir nueva campaña
          <i class="flaticon-plus lh-1 fs-13 position-relative top-1 ms-5"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Nombre Campaña
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Nombre Negocio
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Uso
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Asignación
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Activo
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Estado
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(campana, index) in campanas" :key="index">
              <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                    {{ campana.cnombre }}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ campana.nnombre}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ campana.unombre }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ campana.mtipo }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                <span v-if="campana.cactivo === 'INACTIVO'" class="badge text-outline-danger">Inactivo</span>
                <span v-else class="badge text-outline-success">Activo</span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                <span v-if="campana.cestado === 'INACTIVO'" class="badge text-outline-danger">Inactivo</span>
                <span v-else class="badge text-outline-success">Activo</span>
              </td>
              <td class="shadow-none lh-1 text-end">
                <button type="button"
                  @click="clickCampana(campana)"
                  class="bg-transparent p-0 border-0 text-paragraph fs-15 fs-md-16 fs-lg-18">
                  <i class="flaticon-view"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CrearCampanaModal/>
</template>
  
<script >
import CrearCampanaModal from "@/my-components/Modals/CrearCampanaModal.vue";
export default {
  name: 'CampanasContent',
  components:{
    CrearCampanaModal
  },
  data() {
    return {
      campanas: [],
      campana: {}
    };
  },
  created() {
    this.loadCampanas();
  },
  methods: {
    loadCampanas() {
      let arr = [
        {
          "id_campana": 1,
          "cnombre": "Campaña por default",
          "mtipo": null,
          "unombre": null,
          "nnombre": null,
          "cpromptge": "Editar",
          "cpromptsp": "Editar",
          "cpromptpr": "Editar",
          "cpromptre": "Editar",
          "cpromptpv": "Editar",
          "cpromptsys": "Editar",
          "cfcreado": "2023-11-23T22:11:18.943Z",
          "cactivo": "ACTIVO",
          "cestado": "ACTIVO",
          "cprecio": null
        },
        {
          "id_campana": 2,
          "cnombre": "PhoneStore",
          "mtipo": "Manual",
          "unombre": "Ventas",
          "nnombre": "Negocio Ventas",
          "cpromptge": "Eres Phone Store, una empresa encargada de vender productos de telefonos, como forros para telefonos, cargadores para telefonos, auriculares para telefono. Amplia el catalogo según necesites.",
          "cpromptsp": "¿Necesitas una funda para tu teléfono? ¡Tenemos diferentes estilos y colores!",
          "cpromptpr": "los precios, forros, un solo color, 5$, dos colores 7$, tres colores 9$, multicolor 10$, forros transparentes en 2$, la calidad es de plastico de mercurio, cuando el cliente pregunte por precios deberás añadir que sí usa el cupón ELCUPONSIRVE, obtendrán un 40% de descuento",
          "cpromptre": "¿Quieres proteger tu pantalla? Tenemos protectores de vidrio templado de alta calidad.",
          "cpromptpv": "Quiero que respondas de forma amable, no muy extensa (no más de 30 palabras por pregunta), ofreciéndole alternativas de los planes para que eventualmente contrate un servicio, presentándolas de a una o dos e ir progresivamente orientando la compra.Intenta siempre incentivar a que lleve más de una línea, mostrando los descuentos por múltiples líneas de algunos planes. ",
          "cpromptsys": "actua como un asistente virutal",
          "cfcreado": "2023-11-24T14:42:20.293Z",
          "cactivo": "ACTIVO",
          "cestado": "ACTIVO",
          "cprecio": null
        },
        {
          "id_campana": 3,
          "cnombre": "Campaña WOM",
          "mtipo": "Manual",
          "unombre": "Ventas",
          "nnombre": "Negocio Ventas",
          "cpromptge": "Entre los tags <cultura WOM> se incluyen ciertos lineamientos sobre cómo un asistente debe comunicarse con un   usuario:<cultura WOM>Nos esforzamos por darlo todo. Traspasando límites para satisfacer a nuestros clientes.Nos   atrevemos a marcar la diferencia y definir nuevos estándares.Desafiamos lo establecido en pro de una mejor experiencia   del cliente.Vivimos desde la verdad y la transparencia.</cultura WOM>",
          "cpromptsp": "El usuario te contactó a través de redes sociales porque vio una publicación de una oferta que le llamó la atención   y está interesado en plan telefonía móvil (portabilidad numérica).Dentro de los tags <planes> se encuentra la   información de los nuevos planes de telefonía móvil disponibles y ofertas disponibles:",
          "cpromptpr": "Mejoras de la parrilla actual:✔ Se reduce la parrilla de 7 planes a 5 planes✔ Se unifica el precio de 2 y 3   líneas✔ Planes con más beneficios ( +Gigas +Roaming)✔ Mejoras en descuentos de 12 meses a 18 meses✔ Se elimina el   descuento permanente / Volumetría✔ Nuevos códigos de planes✔ Nuevos Cargos Fijos✔ Descuentos por volumen de líneas   contratadas",
          "cpromptre": "¿Quieres proteger tu pantalla? Tenemos protectores de vidrio templado de alta calidad.",
          "cpromptpv": "Nombre | valor por 1 línea | valor por 2-3 líneas | valor por 4 líneas | apps libres de roaming100 GB | 9.990 |   9.990 | 9.990 | ninguna250 GB | 12.990 | 12.990 | 12.990 | whatsapp400 GB | 15.990 | 7.490 | 6.990 | whatsapp, maps,   uber, gmail, bookingLIBRE 600 GB | 19.990 | 8.490 | 7.990 | whatsapp, maps, uber, gmail, booking LIBRE  PLUS 600 GB |   25.990 | 10.490 | 9.990 | whatsapp, maps, uber, gmail, booking",
          "cpromptsys": "Quiero que actúes como una asistente de ventas de una empresa de telefonía Chilena llamada WOM. Solamente estás   interesada en tus funciones y no en otros temas no relacionados, en el caso que te pregunten por algo externo debes   responder que no estas capacitado.",
          "cfcreado": "2023-11-24T14:42:20.470Z",
          "cactivo": "ACTIVO",
          "cestado": "ACTIVO",
          "cprecio": null
        }
      ]
      this.campanas =  arr.filter(arr => arr.id_campana !== 1);
    },
    clickCampana(campana) {
      this.campana = campana
    },
    formatearFecha(fecha) {
      return fecha.split('T')[0];
    },
  }
};
</script>