<template>
  <div class="row justify-content-center">
    <div v-if="showAlert1" class="alert alert-danger fs-md-15 fs-lg-16" role="alert">
      <p class="text-center">Debe rellenar todos los campos.</p>
    </div>
    <div v-if="showAlert2" class="alert alert-danger fs-md-15 fs-lg-16" role="alert">
      <p class="text-center">El correo o la contraseña proporcionada es incorrecta.</p>
    </div>
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">

          <div class="d-flex align-items-center">
            <img src="../../../assets/images/login/icono.png" width="400" height="400" alt="icono" />
          </div>
          <hr>
          <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
            <div class="card-body p-10">
              <ul class="settings-tabs ps-0 mb-0 list-unstyled">
                <li>
                  <a @click="onChange"
                    class="fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
                    :class="{ disabled: isDisabledAdmin }">
                    Administrador
                  </a>
                </li>
                <li>
                  <a @click="onChange"
                    class="fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
                    :class="{ disabled: isDisabledUser }">
                    Usuario
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <h4 class="text-black fw-bold mb-0">
            Iniciar sesión
          </h4>
          <form @submit.prevent="onSubmit">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label for="email" class="d-block text-black fw-semibold mb-10">
                Correo electrónico
              </label>
              <input v-model="email" id="email" type="email" class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. adam127704@gmail.com" />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label for="password" class="d-block text-black fw-semibold mb-10">
                Contraseña
              </label>
              <input id="password" v-model="password" type="password"
                class="form-control shadow-none rounded-0 text-black" placeholder="**************" />
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit">
              Ingresar
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script >
export default {
  name: "LoginForm",
  data() {
    return {
      email: "demo_admin@virtualiabot.cl",
      password: "123456",
      showAlert1: false,
      showAlert2: false,
      isDisabledAdmin: false,
      isDisabledUser: true

    }
  },
  created() {
    this.loadUserInfo()
  },
  methods: {
    loadUserInfo() {
      const userInfo = sessionStorage.getItem('token');
      if (userInfo) {
        return this.$router.push({ name: 'dashboard' });
      }
    },
    validForm() {
      if (this.email === "" || this.password === "") {
        this.showAlert1 = true
        this.showAlert2 = false
        return false
      }
      return true
    },
    validData() {
      this.showAlert1 = false
      return this.showAlert2 = true
    },
    async signIn() {
      let loginUser = {
        uemail: this.email,
        clave: this.password
      }
      console.log(loginUser)
      if (loginUser.uemail === 'demo_admin@virtualiabot.cl' && loginUser.clave === '123456') {
        sessionStorage.setItem('rol', 'ADMIN')
        sessionStorage.setItem('token', true);
        sessionStorage.setItem('nombre', 'Administrador');
        return this.$router.push({ name: 'dashboard' });
      }
      else if (loginUser.uemail === 'demo_user@virtualiabot.cl' && loginUser.clave === '123456') {
        sessionStorage.setItem('rol', 'USER')
        sessionStorage.setItem('token', true);
        sessionStorage.setItem('nombre', 'Usuario');
        return this.$router.push({ name: 'dashboard' });
      }
      else {
        return this.validData()
      }
    },
    async onSubmit() {
      if (this.validForm()) {
        this.signIn()
      }
    },
    onChange() {
      console.log(this.email)
      if (this.email === "demo_admin@virtualiabot.cl") {
        this.email = "demo_user@virtualiabot.cl"
      } else {
        this.email = "demo_admin@virtualiabot.cl"
      }
    }
  }
};
</script>