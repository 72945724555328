<template>
    <BreadCrumb PageTitle="Finalizadores" />
    <FinalizadoresContent/>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
  import FinalizadoresContent from "../../../my-components/Finalizadores/FinalizadoresContent.vue"

  
  export default defineComponent({
    name: "FinalizadoresPage",
    components: {
      BreadCrumb,
      FinalizadoresContent
    },
  });
  </script>