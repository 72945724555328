<template>
    <div class="row">
        <div class="col-lg-4 col-xxxl-3">
            <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing chat-sidebar">
                <div class="card-body">
                    <div class="chat-tabs">
                        <router-link :to="{ name: 'chats-ia' }"
                            class="text-decoration-none fw-medium transition text-center d-block">
                            IA
                        </router-link>
                        <router-link :to="{ name: 'chats-manual' }"
                            class=" active text-decoration-none fw-medium transition text-center d-block">
                            Manuales
                        </router-link>
                        <router-link :to="{ name: 'chats-finalizados' }"
                            class="text-decoration-none fw-medium transition text-center d-block">
                            Finalizados
                        </router-link>
                    </div>
                    <div class="chat-users-list">
                        <div
                            class="single-user-item position-relative">
                            <div class="d-flex align-items-center">
                                <div class="ms-12">
                                    <span class="title d-block text-black fs-md-15 fs-lg-16 fw-medium">
                                        Jacob Linowiski
                                        <span class="ms-5 text-muted fw-normal fs-12">
                                            10:30 am
                                        </span>
                                    </span>
                                    <span class="d-block text-black text-truncate" style="max-width: 200px;">
                                        Hey Victor! Could you please...
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="single-user-item position-relative">
                            <div class="d-flex align-items-center">
                                <div class="ms-12">
                                    <span class="title d-block text-black fs-md-15 fs-lg-16 fw-medium">
                                        Jacob Linowiski
                                        <span class="ms-5 text-muted fw-normal fs-12">
                                            10:30 am
                                        </span>
                                    </span>
                                    <span class="d-block text-black text-truncate" style="max-width: 200px;">
                                        Hey Victor! Could you please...
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="single-user-item position-relative">
                            <div class="d-flex align-items-center">
                                <div class="ms-12">
                                    <span class="title d-block text-black fs-md-15 fs-lg-16 fw-medium">
                                        Jacob Linowiski
                                        <span class="ms-5 text-muted fw-normal fs-12">
                                            10:30 am
                                        </span>
                                    </span>
                                    <span class="d-block text-black text-truncate" style="max-width: 200px;">
                                        Hey Victor! Could you please...
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="single-user-item position-relative">
                            <div class="d-flex align-items-center">
                                <div class="ms-12">
                                    <span class="title d-block text-black fs-md-15 fs-lg-16 fw-medium">
                                        Jacob Linowiski
                                        <span class="ms-5 text-muted fw-normal fs-12">
                                            10:30 am
                                        </span>
                                    </span>
                                    <span class="d-block text-black text-truncate" style="max-width: 200px;">
                                        Hey Victor! Could you please...
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="single-user-item position-relative">
                            <div class="d-flex align-items-center">
                                <div class="ms-12">
                                    <span class="title d-block text-black fs-md-15 fs-lg-16 fw-medium">
                                        Jacob Linowiski
                                        <span class="ms-5 text-muted fw-normal fs-12">
                                            10:30 am
                                        </span>
                                    </span>
                                    <span class="d-block text-black text-truncate" style="max-width: 200px;">
                                        Hey Victor! Could you please...
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-xxxl-9">
            <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing chat-card">
                <div
                  class="card-head box-shadow bg-white d-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
                  <div class="d-flex align-items-center">
                        <div class="ms-15">
                            <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium">Brad Traversy</span>
                            <span class="d-block">EMOCIÓN - CATEGORIA</span>
                        </div>
                    </div>
                  <div class="dropdown">
                      <button
                          class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                          type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="flaticon-dots"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="#" data-bs-toggle="modal" data-bs-target="#AsignarChatModal" type="button">
                                  <i class="ph-bold flaticon-customer-service me-8"></i> Cambiar asignación
                              </a>
                          </li>
                          <li>
                              <a class="dropdown-item d-flex align-items-center" href="#" data-bs-toggle="modal" data-bs-target="#CrearProspectoModal" type="button">
                                  <i class="ph-bold flaticon-taget  me-8"></i> Añadir prospecto
                              </a>
                          </li>
                          <li>
                              <a class="dropdown-item d-flex align-items-center" href="#" data-bs-toggle="modal" data-bs-target="#FinalizarChatModal" type="button">
                                  <i class="ph-bold flaticon-taget flaticon-pin-1 me-8"></i> Finalizar
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
                <div class="card-body p-15 p-sm-20 p-md-25">
                    <div class="chat-list" ref="chatBox">
                            <div class="chat-item position-relative">
                                <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-10">
                                    Brad Traversy
                                    <span class="fs-12 text-muted fw-noarmal ms-5">10:29</span>
                                </span>
                                <div class="message position-relative">
                                    <div class="inner">
                                        <p class="fs-md-15 fs-lg-16 lh-base">
                                            Hey Victor! Could you please give me the transaction number
                    that we provide yesterday? We need this for the invoice.
                    After that I will give you the full invoice with payment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-item position-relative right">
                                <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-10">
                                    Virtualia
                                    <span class="fs-12 text-muted fw-noarmal ms-5">10:29</span>
                                </span>
                                <div class="message position-relative">
                                    <div class="inner">
                                        <p class="fs-md-15 fs-lg-16 lh-base">
                                            Hola, ¿En qué te puedo ayudar el día de hoy?
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <form>
                        <div class="write-your-message position-relative">
                            <div class="write-message position-relative">
                                <input disabled type="text" class="input-message d-block w-100 text-black fs-14"
                                    placeholder="Esta conversación se encuentra asignada." />
                            </div>
                            <div class="buttons-list items-center">
                                <button type="button"
                                    class="border-0 d-inline-block position-relative text-center transition active">
                                    <i class="flaticon-airplane"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <FinalizarChatModal/>
    <AsignarChatModal/>
    <CrearProspectoModal/>
</template>
  
<script lang="ts">
import FinalizarChatModal from "@/my-components/Modals/FinalizarChatModal.vue";
import AsignarChatModal from "@/my-components/Modals/AsignarChatModal.vue"
import CrearProspectoModal from "@/my-components/Modals/CrearProspectoModal.vue"
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChangeLog",
  components:{
    FinalizarChatModal,
    AsignarChatModal,
    CrearProspectoModal
  }
});
</script>