<template>
    <div class="modal fade createNewModal" id="CrearConexionModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-15 p-md-40">
                <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
                    <h5 class="modal-title fw-bold text-black">Generar comunicación teléfono-campaña</h5>
                </div>
                <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
                    <form action="#">
                        <div class="row">
                            <div class="col-md-6">


                                <label for="inputEmail4" class="form-label fw-medium">Teléfono disponible:</label>


                                <select class="form-select form-select-lg shadow-none fs-16 text-black mb-15 mb-md-20">


                                    <option selected>Seleccione un teléfono</option>


                                    <option value="1">123456789</option>


                                    <option value="2">987654321</option>

                                </select>


                            </div>
                            <div class="col-md-6">
                                <label for="overviewEmail" class="form-label fw-medium">Campaña disponible:</label>
                                <select class="form-select form-select-lg shadow-none fs-16 text-black mb-15 mb-md-20">


                                    <option selected>Seleccione una campaña</option>


                                    <option value="1">Campaña 1</option>


                                    <option value="2">Campaña 2</option>

                                </select>


                            </div>



                            <button  type="button"  data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary">Añadir</button>
                        </div>
                    </form>
                </div>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "CrearFinalizadorModal",
};
</script>