<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <button href="#" data-bs-toggle="modal" data-bs-target="#CrearProspectoModal" type="button"
          class="default-btn border-0 transition d-block w-100 text-center position-relative text-white fs-md-15 fs-lg-16 fw-medium pt-18 pb-18 ps-15 pe-15 d-inline-block">
          Añadir nuevo prospecto
          <i class="flaticon-plus lh-1 fs-13 position-relative top-1 ms-5"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Nombre y Apellido
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                RUT - DV
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Teléfono
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Correo electrónico
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Creado
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prospecto, index) in prospectos" :key="index">
              <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                    {{ prospecto.plnombres}} {{prospecto.plapellidos }}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ prospecto.plrut}}-{{prospecto.pldv }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ prospecto.pltelefono }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ prospecto.plemail }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ formatearFecha(prospecto.plemail) }}
              </td>
              <td class="shadow-none lh-1 text-end">
                <button type="button" @click="clickProspecto(prospecto)"
                  class="bg-transparent p-0 border-0 text-paragraph fs-15 fs-md-16 fs-lg-18">
                  <i class="flaticon-view"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CrearProspectoModal/>
</template>
  
<script >
 import CrearProspectoModal from "@/my-components/Modals/CrearProspectoModal.vue"
export default {
  name: 'ProspectosContent',
  components:{
    CrearProspectoModal
  },
  data() {
    return {
      prospectos: [],
      prospecto: {}
    };
  },
  created() {
    this.loadProspectos();
  },
  methods: {
    loadProspectos() {
      this.prospectos = [
        {
          "id_prospecto": 1,
          "id_conversacion": 100,
          "id_pais": 1,
          "plfcreado": "2021-01-01T12:00:00Z",
          "plnombres": "Juan",
          "plapellidos": "Pérez",
          "plrut": "12345678",
          "pldv": "K",
          "pltelefono": 123456789,
          "plemail": "juan.perez@example.com"
        },
        {
          "id_prospecto": 2,
          "id_conversacion": 101,
          "id_pais": 1,
          "plfcreado": "2021-01-02T13:00:00Z",
          "plnombres": "Ana",
          "plapellidos": "García",
          "plrut": "8765432",
          "pldv": "1",
          "pltelefono": 987654321,
          "plemail": "ana.garcia@example.com"
        },
        {
          "id_prospecto": 3,
          "id_conversacion": 102,
          "id_pais": 1,
          "plfcreado": "2021-01-03T14:00:00Z",
          "plnombres": "Luis",
          "plapellidos": "Martínez",
          "plrut": "11223344",
          "pldv": "8",
          "pltelefono": 112233445,
          "plemail": "luis.martinez@example.com"
        },
        {
          "id_prospecto": 4,
          "id_conversacion": 103,
          "id_pais": 2,
          "plfcreado": "2021-01-04T15:00:00Z",
          "plnombres": "María",
          "plapellidos": "López",
          "plrut": "4433221",
          "pldv": "3",
          "pltelefono": 556677889,
          "plemail": "maria.lopez@example.com"
        }
      ]
    },
    clickProspecto(prospecto) {
      this.prospecto = prospecto
      this.$router.push({ name: 'conversaciones-detalles' });
    },
    formatearFecha(fecha) {
      return fecha.split('T')[0];
    }
  }
};
</script>