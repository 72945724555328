<template>
    <BreadCrumb PageTitle="Dashboard" />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";

  
  export default defineComponent({
    name: "DashboardPage",
    components: {
      BreadCrumb,
    },
  });
  </script>