<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12 col-xxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-sterile-box text-primary"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Negocio
          </h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-calendar"></i>
                Nombre Negocio:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                Negocio Ventas
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-atm"></i>
                Nombre Departamento:
              </div>
              <span
                class="d-flex align-items-center text-paragraph fs-md-15 fs-lg-16"
              >
                Departamento de Ventas
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-12 col-xxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-user-3 text-success"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Detalle Contacto
          </h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-user-1"></i>
                Nombre Contacto:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                Jonathon Ronan
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-phone-call"></i>
                Teléfono:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                +31-234-324-6587
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-12 col-xxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-technical-support text-success"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Usuario asignado
          </h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-user-1"></i>
                Nombre Usuario:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                David Morales
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-email"></i>
                Correo:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                david.morales@laavanzada.cl
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-12 col-xxl-6">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-document text-info"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">Conversación</h5>
        </div>
        <div class="card-body">
            <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-copy"></i>
                Nombre Campaña:
              </div>
              <span class="d-block text-primary fs-md-15 fs-lg-16">
                Campaña Ventas
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-copy"></i>
                Nro de teléfono:
              </div>
              <span class="d-block text-primary fs-md-15 fs-lg-16">
                1234567890
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-copy"></i>
                Uso:
              </div>
              <span class="d-block text-primary fs-md-15 fs-lg-16">
                Ventas
              </span>
            </li>
            </ul>
            <hr>
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-copy"></i>
                Primera Actividad:
              </div>
              <span class="d-block text-primary fs-md-15 fs-lg-16">
                2023-11-29 21:02:00
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-express-delivery"></i>
                Última Actividad:
              </div>
              <span class="d-block text-primary fs-md-15 fs-lg-16">
                2023-11-29 22:00:16
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-shield"></i>
                Objetivo Identificado:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">Compra de accesorios</span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-shield"></i>
                Emoción Identificada:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">FELIZ</span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-shield"></i>
                Conclusión:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">Nuevo Prospecto</span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-shield"></i>
                Relación:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">Positivo</span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-shield"></i>
                Comentario:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">Se añade nuevo prospecto, interesado en la compra de accesorios teléfonicos</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-12 col-xxl-6">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-document text-info"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">Prospecto</h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-copy"></i>
                Nombre y Apellido:
              </div>
              <span class="d-block text-primary fs-md-15 fs-lg-16">
                Juan Pérez
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-express-delivery"></i>
                RUT - DV:
              </div>
              <span class="d-block text-primary fs-md-15 fs-lg-16">
                12345678-K
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-shield"></i>
                Teléfono
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">123456789</span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-shield"></i>
                Correo:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">juan.perez@example.com</span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-shield"></i>
                Creado:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">2023-11-29 22:05:23</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script >
export default {
    name: 'ProspectosDetallesContent',
};
</script>