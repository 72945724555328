<template>
    <div class="modal fade createNewModal" id="CrearProspectoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-15 p-md-40">
                <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
                    <h5 class="modal-title fw-bold text-black">Crear nuevo prospecto</h5>
                </div>
                <form action="#" class="row g-3">

                    <div class="col-md-6">


<label for="inputEmail4" class="form-label fw-medium">Nombres</label>


<input type="email" class="form-control shadow-none fs-md-15 text-black" id="inputEmail4">


</div>
<div class="col-md-6">


<label for="inputEmail4" class="form-label fw-medium">Apellidos</label>


<input type="email" class="form-control shadow-none fs-md-15 text-black" id="inputEmail4">


</div>

                    <div class="col-md-6">


                        <label for="inputEmail4" class="form-label fw-medium">RUT</label>


                        <input type="email" class="form-control shadow-none fs-md-15 text-black" id="inputEmail4">


                    </div>


                    <div class="col-md-6">


                        <label for="inputPassword4" class="form-label fw-medium">DV</label>


                        <input type="password" class="form-control shadow-none fs-md-15 text-black" id="inputPassword4">


                    </div>

                    <div class="col-md-6">


                        <label for="inputEmail4" class="form-label fw-medium">Correo electrónico</label>


                        <input type="email" class="form-control shadow-none fs-md-15 text-black" id="inputEmail4">


                    </div>


                    <div class="col-md-6">


                        <label for="inputPassword4" class="form-label fw-medium">Nro teléfono</label>


                        <input type="password" class="form-control shadow-none fs-md-15 text-black" id="inputPassword4">


                    </div>


                    <div class="col-12">


                        <button  type="button"  data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary">Añadir</button>


                    </div>

                </form>

            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "CrearProspectoModal",
};
</script>