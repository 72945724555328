<template>
    <BreadCrumb PageTitle="Campañas"/>
    <CampanasContent/>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
  import CampanasContent from "../../../my-components/Campanas/CampanasContent.vue"

  
  export default defineComponent({
    name: "CampanasPage",
    components: {
      BreadCrumb,
      CampanasContent
    },
  });
  </script>