<template>
    <div class="col-xxxl-12">
      <button  href="#" data-bs-toggle="modal" data-bs-target="#CrearFinalizadorModal" type="button"
        class="default-btn mb-25 border-0 transition d-block w-100 text-center position-relative text-white fs-md-15 fs-lg-16 fw-medium pt-18 pb-18 ps-15 pe-15">
        Crear nuevo finalizador
        <i class="flaticon-plus lh-1 fs-13 position-relative top-1 ms-5"></i>
      </button>
      <div class="card mb-25 border-0 rounded-0 bg-white working-schedule-box">
        <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
          <h6 class="card-title fw-bold mb-12">Listado finalizadores</h6>
          <div class="schedule-list mt-15 mt-md-20" v-for="(finalizador, index) in finalizadores" :key="index">
            <div v-if="finalizador.conivel != 'Positivo'"
              class="list-item pt-15 pb-15 pt-md-20 pb-md-20 ps-20 pe-15 position-relative bg-f2f1f9">
              <div class="content">
                <div class="d-flex align-items-center mb-5">
                  <h6 class="fw-medium mb-0">
                    
                    {{ finalizador.conombre }}
                  </h6>
                </div>
                <span class="d-block text-black fw-semibold fs-md-15 mb-1">
                  {{ formatearFecha(finalizador.cofecreado) }}
                </span>
              </div>
            </div>
            <div v-else class="list-item pt-15 pb-15 pt-md-20 pb-md-20 ps-20 pe-15 position-relative bg-ecf3f2">
              <div class="content">
                <div class="d-flex align-items-center mb-5">
                  <h6 class="fw-medium mb-0">
                    
                    {{ finalizador.conombre }}
                  </h6>
                </div>
                <span class="d-block text-black fw-semibold fs-md-15 mb-1">
                  {{ formatearFecha(finalizador.cofecreado) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CrearFinalizadorModel/>
  </template>
  
  <script >
  import CrearFinalizadorModel from "@/my-components/Modals/CrearFinalizadorModal.vue"
  export default {
    name: 'FinalizadoresContent',
    components:{
      CrearFinalizadorModel
    },
    data() {
      return {
        finalizadores: [],
      };
    },
    created() {
      this.loadFinalizadores();
    },
    methods: {
      loadFinalizadores() {
        this.finalizadores = [
        {
            "id_conclusion": 1,
            "conombre": "Venta",
            "conivel": "Positivo",
            "cofecreado": "2023-11-24T14:53:18.326Z"
        },
        {
            "id_conclusion": 2,
            "conombre": "Desiste",
            "conivel": "Negativo",
            "cofecreado": "2023-11-24T14:53:46.894Z"
        },
        {
            "id_conclusion": 5,
            "conombre": "Sin respuesta",
            "conivel": "Negativo",
            "cofecreado": "2023-11-26T08:23:02.171Z"
        },
        {
            "id_conclusion": 6,
            "conombre": "Nuevo prospecto",
            "conivel": "Positivo",
            "cofecreado": "2023-11-26T08:23:55.585Z"
        },
        {
            "id_conclusion": 7,
            "conombre": "Test",
            "conivel": "Positivo",
            "cofecreado": "2023-11-26T08:29:46.997Z"
        }
        ]
      },
      formatearFecha(fecha) {
        return fecha.split('T')[0];
      }
    }
  };
  </script>