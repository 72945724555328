<template>
    <BreadCrumb PageTitle="Conexiones" />
    <TelefonosContent/>
    <ConexionesContent/>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
import TelefonosContent from "../../../my-components/Conexiones/TelefonosContent.vue"
import ConexionesContent from "../../../my-components/Conexiones/ConexionesContent.vue"
  
  export default defineComponent({
    name: "ConexionesPage",
    components: {
      BreadCrumb,
      TelefonosContent,
      ConexionesContent
    },
  });
  </script>