<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <button href="#" data-bs-toggle="modal" data-bs-target="#CrearNegocioModal" type="button"
          class="default-btn border-0 transition d-block w-100 text-center position-relative text-white fs-md-15 fs-lg-16 fw-medium pt-18 pb-18 ps-15 pe-15 d-inline-block">
          Añadir nuevo negocio
          <i class="flaticon-plus lh-1 fs-13 position-relative top-1 ms-5"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Nombre
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                RUT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Depto
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Contacto
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Dirección
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Teléfono
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Correo electrónico
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Estado
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(negocio, index) in negocios" :key="index">
              <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                    {{ negocio.nnombre }}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ negocio.erut }}-{{ negocio.edv }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ negocio.ndepto }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ negocio.ncontacto }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ negocio.ndireccion }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ negocio.ntelefono }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ negocio.nmail }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                <span v-if="negocio.nestado === 'INACTIVO'" class="badge text-outline-danger">Inactivo</span>
                <span v-else class="badge text-outline-success">Activo</span>
              </td>
              <td class="shadow-none lh-1 text-end">
                <button type="button"
                  @click="clickNegocio(negocio)"
                  class="bg-transparent p-0 border-0 text-paragraph fs-15 fs-md-16 fs-lg-18">
                  <i class="flaticon-view"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CrearNegocioModal/>
</template>
  
<script >
import CrearNegocioModal from "@/my-components/Modals/CrearNegocioModal.vue";
export default {
  name: 'NegociosContent',
  components:{
    CrearNegocioModal
  },
  data() {
    return {
      negocios: [],
      negocio: {}
    };
  },
  created() {
    this.loadNegocios();
  },
  methods: {
    loadNegocios() {
      this.negocios = [
        {
          "id_negocio": 1,
          "id_organizacion": 10,
          "erut": 12345678,
          "edv": "A",
          "nnombre": "Negocio Uno",
          "ndepto": "Ventas",
          "ndireccion": "Calle Falsa 123",
          "ntelefono": "123-456-7890",
          "ncontacto": "Juan Pérez",
          "nmail": "contacto@negociouno.com",
          "nfcreado": "2021-01-01T12:00:00Z",
          "nestado": "ACTIVO"
        },
        {
          "id_negocio": 2,
          "id_organizacion": 11,
          "erut": 87654321,
          "edv": "B",
          "nnombre": "Negocio Dos",
          "ndepto": "Marketing",
          "ndireccion": "Avenida Principal 456",
          "ntelefono": "234-567-8901",
          "ncontacto": "Ana García",
          "nmail": "contacto@negociodos.com",
          "nfcreado": "2021-02-02T13:00:00Z",
          "nestado": "INACTIVO"
        },
        {
          "id_negocio": 3,
          "id_organizacion": 12,
          "erut": 11223344,
          "edv": "C",
          "nnombre": "Negocio Tres",
          "ndepto": "Finanzas",
          "ndireccion": "Boulevard del Parque 789",
          "ntelefono": "345-678-9012",
          "ncontacto": "Carlos López",
          "nmail": "contacto@negociotres.com",
          "nfcreado": "2021-03-03T14:00:00Z",
          "nestado": "ACTIVO"
        },
        {
          "id_negocio": 4,
          "id_organizacion": 13,
          "erut": 44556677,
          "edv": "D",
          "nnombre": "Negocio Cuatro",
          "ndepto": "Soporte Técnico",
          "ndireccion": "Plaza Central 101",
          "ntelefono": "456-789-0123",
          "ncontacto": "Laura Martínez",
          "nmail": "contacto@negociocuatro.com",
          "nfcreado": "2021-04-04T15:00:00Z",
          "nestado": "INACTIVO"
        },
        {
            "id_negocio": 5,
            "id_organizacion": 1,
            "erut": 11111111,
            "edv": "1",
            "nnombre": "Negocio Ventas",
            "ndepto": "Ventas",
            "ndireccion": "Calle test",
            "ntelefono": "9999999",
            "ncontacto": "Contacto test",
            "nmail": "test@gmai.com",
            "nfcreado": "2023-11-24T14:52:35.910Z",
            "nestado": "ACTIVO"
        }
      ]
    },
    clickNegocio(negocio) {
      this.negocio = negocio
    },
    formatearFecha(fecha) {
      return fecha.split('T')[0];
    },
  }
};
</script>