<template>
    <div class="modal fade createNewModal" id="CrearTelefonoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-15 p-md-40">
                <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
                    <h5 class="modal-title fw-bold text-black">Finalizar conversación</h5>
                </div>
                <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
                    <form action="#" >
                        <div class="col-md-6">
                                <label for="overviewEmail" class="form-label fw-medium">Seleccione un negocio:</label>
                                <select class="form-select form-select-lg shadow-none fs-16 text-black mb-15 mb-md-20">


                                    <option selected>Seleccione un negocio</option>


                                    <option value="1">Negocio 1</option>


                                    <option value="2">Negocio 2</option>

                                </select>


                            </div>
                        <a class="btn btn-primary me-10" data-bs-toggle="collapse" href="#basicCollapse" role="button"
                            aria-expanded="false" aria-controls="basicCollapse">
                            Obtener QR
                        </a>
                        <div class="mt-12"></div>
                        <div class="collapse" id="basicCollapse">
                            <div class="card card-body">
                                <img src="../../assets/images/qr-code.png" width="44" height="44" class="rounded-circle user"
                alt="user" />
                            </div>
                        </div>



                        <button  type="button"  data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary">Añadir</button>

                    </form>
                </div>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "CrearFinalizadorModal",
};
</script>