<template>
    <BreadCrumb PageTitle="Usuarios" />
    <UsuarioContent/>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
  import UsuarioContent from "../../../my-components/Usuarios/UsuariosContent.vue"

  
  export default defineComponent({
    name: "UsuariosPage",
    components: {
      BreadCrumb,
      UsuarioContent
    },
  });
  </script>