<template>
    <MainHeader />
    <MainSidebar />
    <div class="main-content d-flex flex-column transition overflow-hidden">
      <router-view />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent} from "vue";
  
  import MainHeader from "./MainHeader.vue";
  import MainSidebar from "./MainSidebar.vue";
  
  export default defineComponent({
    name: "App",
    components: {
      MainHeader,
      MainSidebar,
    },
  });
  </script>