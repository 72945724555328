<template>
    <BreadCrumb PageTitle="Detalles" />
    <ProspectosDetallesContent/>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../../components/Common/BreadCrumb.vue";
  import ProspectosDetallesContent from "../../../../my-components/Prospectos/Detalles/ProspectosDetallesContent.vue"

  
  export default defineComponent({
    name: "ProspectosPage",
    components: {
      BreadCrumb,
      ProspectosDetallesContent
    },
  });
  </script>