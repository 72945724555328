<template>
    <BreadCrumb PageTitle="MIS CHATS - Activos" />
    <ChatsActivosContent/>
  </template>
  
  <script lang="ts">

import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../../components/Common/BreadCrumb.vue"
  import ChatsActivosContent from "../../../../my-components/MisChats/ChatsActivos/ChatsActivosContent.vue"
  
  export default defineComponent({
    name: "ConexionesPage",
    components: {
      BreadCrumb,
      ChatsActivosContent
    },
  });
  </script>