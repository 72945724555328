<template>
  <header :class="[
    'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed',
    { sticky: isSticky },
  ]" id="header">
    <div class="row align-items-center">
      <div class="col-xl-4 col-lg-5 col-md-6">
        <div class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center">
          <button class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
            id="header-burger-menu" @click="stateStoreInstance.onChange">
            <i class="flaticon-menu-3"></i>
          </button>
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 col-md-6">
        <div class="header-right-side d-flex align-items-center justify-content-center justify-content-md-end">
          <div class="dropdown profile-dropdown">
            <button
              class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <div
                class="rounded-1 justify-content-center bg-gray align-items-center d-flex fw-bold fs-18 text-primary text-center w-50 h-50">
                {{ fetchAvatar(this.nombre) }}</div>
              <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">{{ this.nombre }}</span>
                <span class="text-body-emphasis fw-semibold fs-13">{{ this.rol }}</span>
              </span>
            </button>
            <div class="dropdown-menu rounded-0 bg-white border-0 start-}}auto end-0">
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li class="text-body-secondary fw-semibold transition position-relative" @click="cerrarSesion">
                  <i class="flaticon-logout"></i>
                  Cerrar sesión
                  <router-link to="/auth"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import stateStore from "../utils/store"

export default defineComponent({
  name: "MainHeader",
  data() {
    return {
      rol: "",
      nombre: ""
    };
  },
  setup() {
    const stateStoreInstance = stateStore;
    const isSticky = ref(false);
    onMounted(() => {
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        isSticky.value = scrollPos >= 100;
      });
    });
    return {
      isSticky,
      stateStoreInstance,
    };
  },
  methods: {
    cerrarSesion() {
      // Borrar la información de sesión
      sessionStorage.removeItem('rol')
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('nombre');
      this.rol = ""
      this.nombre = ""
      // Redirigir al usuario a /login
      this.$router.push({ name: 'auth' });
    },
    fetchAvatar(fullName) {
      if (fullName) {
        // Dividimos el nombre completo en palabras y filtramos los espacios vacíos por si acaso
        const nameParts = fullName.split(' ').filter(Boolean);
        // Mapeamos las partes a sus iniciales en mayúsculas
        const initials = nameParts.map(name => name[0].toUpperCase());
        // Unimos las iniciales con un espacio
        return initials.join(' ');
      }
    },
    loadToken() {
      let token = sessionStorage.getItem('token'); // Asegúrate de usar la clave correcta
      if (token) {
        this.rol = sessionStorage.getItem('rol') || '';
        this.nombre = sessionStorage.getItem('nombre') || '';
      }
      else {
        this.$router.push({ name: 'auth' });
      }
    },
  },
  mounted(){
    this.loadToken()
  }
})
</script>