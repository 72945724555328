<template>
    <BreadCrumb PageTitle="Contactos" />
    <ContactosContent/>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
  import ContactosContent from "../../../my-components/Contactos/ContactosContent.vue"

  
  export default defineComponent({
    name: "ContactosPage",
    components: {
      BreadCrumb,
      ContactosContent
    },
  });
  </script>