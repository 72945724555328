<template>
  <div :class="[
    'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
    { active: stateStoreInstance.open },
  ]" id="sidebar-area">
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link to="/" class="d-flex align-items-center text-white text-decoration-none">
        <img src="../assets/images/favicon.png" alt="logo-icon" width="60" height="60" />
        <span class="fw-bold ms-10">Virtualia</span>
      </router-link>
      <div class="border-bottom"></div>
      <button class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange">
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion" id="sidebarNavAccordion">
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
        </li>
        <li class="sidebar-nav-item">
          <router-link :to="{ name: 'dashboard' }" class="sidebar-nav-link d-block">
            <i class="flaticon-more-1"></i>
            <span class="title">Dashboard</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link :to="{ name: 'changelog' }" class="sidebar-nav-link d-block">
            <i class="flaticon-award-1"></i>
            <span class="title">Changelog <span class="badge text-bg-light rounded-pill fs-11 d-inline-block">Beta
                1.0.0</span></span>
          </router-link>
        </li>
        <li class="sub-title sidebar-nav-item">
          <span class="d-block text-uppercase fw-medium">Aplicación</span>
        </li>
        <li class="sidebar-nav-item">
          <router-link :to="{ name: 'mis-chats' }" class="sidebar-nav-link d-block">
            <i class="flaticon-chat-1"></i>
            <span class="title">Mis Chats</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item" v-if="isAdmin">
          <router-link :to="{ name: 'chats' }" class="sidebar-nav-link d-block">
            <i class="flaticon-chat"></i>
            <span class="title">Chats</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item" v-if="isAdmin">
          <router-link :to="{ name: 'chats-control' }" class="sidebar-nav-link d-block">
            <i class="flaticon-communication"></i>
            <span class="title">Chats Control</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
          <a href="#" class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse" data-bs-target="#sidebarCollapseSix" aria-expanded="false"
            aria-controls="sidebarCollapseSix">
            <i class="flaticon-people"></i>
            <span class="title">Contactos y prospecto</span>
          </a>
          <div id="sidebarCollapseSix" class="accordion-collapse collapse" data-bs-parent="#sidebarNavAccordion">
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link :to="{ name: 'contactos' }" class="sidebar-sub-menu-link">
                    Contactos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link :to="{ name: 'prospectos' }" class="sidebar-sub-menu-link">
                    Prospecto
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sub-title sidebar-nav-item" v-if="isAdmin">
          <span class="d-block text-uppercase fw-medium">Conexiones</span>
        </li>
        <li class="sidebar-nav-item" v-if="isAdmin">
          <router-link :to="{ name: 'telefonos' }" class="sidebar-nav-link d-block">
            <i class="flaticon-phone-call"></i>
            <span class="title">Teléfonos</span>
          </router-link>
        </li>
        <li class="sub-title sidebar-nav-item" v-if="isAdmin">
          <span class="d-block text-uppercase fw-medium">Mantenedores</span>
        </li>
        <li class="sidebar-nav-item" v-if="isAdmin">
          <router-link :to="{ name: 'usuarios' }" class="sidebar-nav-link d-block">
            <i class="flaticon-user-3"></i>
            <span class="title">Usuarios</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0" v-if="isAdmin">
          <a href="#" class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse" data-bs-target="#sidebarCollapseSeven" aria-expanded="false"
            aria-controls="sidebarCollapseSeven">
            <i class="flaticon-layer-2"></i>
            <span class="title">Negocios y campañas</span>
          </a>
          <div id="sidebarCollapseSeven" class="accordion-collapse collapse" data-bs-parent="#sidebarNavAccordion">
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link :to="{ name: 'negocios' }" class="sidebar-sub-menu-link">
                    Negocios
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link :to="{ name: 'campanas' }" class="sidebar-sub-menu-link">
                    Campañas
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item" v-if="isAdmin">
          <router-link :to="{ name: 'finalizadores'}" class="sidebar-nav-link d-block">
            <i class="flaticon-edit"></i>
            <span class="title">Finalizadores</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import stateStore from "../utils/store";

export default defineComponent({
  name: "MainSidebar",
  data() {
    return {
      isAdmin: false
    };
  },
  created() {
    this.loadToken();
  },
  setup() {
    const stateStoreInstance = stateStore;
    return {
      stateStoreInstance,
    };
  },
  methods: {
    loadToken() {
      let token = sessionStorage.getItem('token'); // Asegúrate de usar la clave correcta
      if (token) {
        if (sessionStorage.getItem('rol') === 'ADMIN') {
          this.isAdmin = true
        }
      }
      else {
        this.$router.push('/auth');
      }
    }
  },
});
</script>