<template>
    <BreadCrumb PageTitle="CHATS - Transferir" />
    <ChatsTransferContent/>
  </template>
  
  <script lang="ts">

import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../../components/Common/BreadCrumb.vue"
  import ChatsTransferContent from "../../../../my-components/ChatsControl/ChatsTransfers/ChatsTransfersContent.vue"
  
  export default defineComponent({
    name: "ConexionesPage",
    components: {
      BreadCrumb,
      ChatsTransferContent
    },
  });
  </script>