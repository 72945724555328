import { createWebHistory, createRouter } from "vue-router";
//Inicio de sesión
import AuthView from "../my-views/Authentication/Login/LoginView.vue"
//MainLayout component
import MainLayout from "../my-layout/MainLayout.vue"
//Inicio
import DashboardView from "../my-views/Inicio/Dashboard/DashboardView.vue"
import ChangeLogView from "../my-views/Inicio/ChangeLog/ChangeLogView.vue"
//Aplicaciones
//Chats control
import ChatsControlTransferView from "../my-views/Aplicaciones/ChatsControl/ChatsTransfers/ChatsTransferView.vue"
import ChatsControlEntrantesView from "../my-views/Aplicaciones/ChatsControl/ChatsEntrantes/ChatsEntrantesView.vue"
//Mis chats
import MisChatsFinalizadosView from "../my-views/Aplicaciones/MisChats/ChatsFinalizados/ChatsFinalizadosView.vue"
import MisChatsActivosView from "../my-views/Aplicaciones/MisChats/ChatsActivos/ChatsActivosView.vue"
//Chats
import ChatsFinalizadosView from "../my-views/Aplicaciones/Chats/ChatsFinalizados/ChatsFinalizadosView.vue"
import ChatsIaView from "../my-views/Aplicaciones/Chats/ChatsIa/ChatsIaView.vue"
import ChatsManualView from "../my-views/Aplicaciones/Chats/ChatsManual/ChatsManualView.vue"

//ContactoProspecto
import ProspectosView from "../my-views/ContactoProspecto/Prospectos/ProspectosView.vue"
import ProspectosDetallesView from "../my-views/ContactoProspecto/Prospectos/Detalles/ProspectosDetallesView.vue"
import ContactosView from "../my-views/ContactoProspecto/Contactos/ContactosView.vue"
//Conexiones
import ConexionesView from "../my-views/Conexiones/Conexiones/ConexionesView.vue"
//Mantenedores 
import UsuariosView from "../my-views/Mantenedores/Usuarios/UsuariosView.vue"
import NegociosView from "../my-views/Mantenedores/Negocios/NegociosView.vue"
import CampanasView from "../my-views/Mantenedores/Campanas/CampanasView.vue"
import FinalizadoresView from "../my-views/Mantenedores/Finalizadores/FinalizadoresView.vue"
//Pages
import NotFoundPage from "../my-pages/ErrorPage.vue"
import UnauthorizedPage from "../my-pages/UnauthorizedPage.vue"

function loadToken() {
  const rol = sessionStorage.getItem('rol'); // Asegúrate de usar la clave correcta
  if (rol){
    return rol
  }
  return null
}

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: AuthView,
  },
  {
    path: "/login",
    name: "login",
    redirect: "/auth",
  },
  {
    path: "/",
    component: MainLayout,
    redirect: { name: 'auth' },
    children: [
      {
        path: "inicio",
        name: "inicio",
        redirect: { name: 'dashboard' },
        children: [
          {
            path: "dashboard",
            name: "dashboard",
            component: DashboardView,
            meta: { roles: ['ADMIN','USER'] },
          },
          {
            path: "changelog",
            name: "changelog",
            component: ChangeLogView,
            meta: { roles: ['ADMIN','USER'] },
          },
        ],
      },
      {
        path: "aplicaciones",
        name: "aplicaciones",
        redirect: {name: 'mis-chats-activos'},
        children: [
          {
            path: "mis-chats",
            name: "mis-chats",
            redirect: {name: 'mis-chats-activos'},
            children: [
              {
                path: "finalizados",
                name: "mis-chats-finalizados",
                component:MisChatsFinalizadosView,
                meta: { roles: ['ADMIN','USER'] },
                children:[
                  {
                    path:"detalles",
                    name:"conversaciones-detalles",
                    meta: { roles: ['ADMIN','USER'] },
                    component: ProspectosDetallesView
                  }
                ]
              },
              {
                path: "activos",
                name: "mis-chats-activos",
                component: MisChatsActivosView,
                meta: { roles: ['ADMIN','USER'] },
              },
            ],
          },
          {
            path: "chats",
            name: "chats",
            redirect: {name: 'chats-ia'},
            children: [
              {
                path: "ia",
                name: "chats-ia",
                component: ChatsIaView,
                meta: { roles: ['ADMIN'] },
              },
              {
                path: "manual",
                name: "chats-manual",
                component: ChatsManualView,
                meta: { roles: ['ADMIN'] },
              },
              {
                path: "finalizados",
                name: "chats-finalizados",
                component: ChatsFinalizadosView,
              },
            ],
          },
          {
            path: "chats-control",
            name: "chats-control",
            redirect: {name: 'chats-control-transfer'},
            children: [
              {
                path: "transfer",
                name: "chats-control-transfer",
                component: ChatsControlTransferView,
                meta: { roles: ['ADMIN'] },
              },
              {
                path: "entrantes",
                name: "chats-control-entrantes",
                component: ChatsControlEntrantesView,
                meta: { roles: ['ADMIN'] },
              },
            ],
          },
        ],
      },
      {
        path: "contactos-prospectos",
        name: "contactos-prospectos",
        redirect: { name: 'contactos' },
        children: [
          {
            path: "contactos",
            name: "contactos",
            component: ContactosView,
            meta: { roles: ['ADMIN','USER'] },
          },
          {
            path: "prospectos",
            name: "prospectos",
            component: ProspectosView,
            meta: { roles: ['ADMIN','USER'] },
            children:[
              {
                path:"detalles",
                name:"prospectos-detalles",
                meta: { roles: ['ADMIN','USER'] },
                component: ProspectosDetallesView
              }
            ]
          },
        ],
      },
      {
        path: "conexiones",
        name: "conexiones",
        redirect: { name: 'telefonos' },
        children: [
          {
            path: "telefonos",
            name: "telefonos",
            component: ConexionesView,
            meta: { roles: ['ADMIN'] },
          },
        ],
      },
      {
        path: "mantenedores",
        name: "mantenedores",
        redirect: { name: 'usuarios' },
        children: [
          {
            path: "usuarios",
            name: "usuarios",
            component: UsuariosView,
            meta: { roles: ['ADMIN'] },
          },
          {
            path: "negocios",
            name: "negocios",
            component: NegociosView,
            meta: { roles: ['ADMIN'] },
          },
          {
            path: "campanas",
            name: "campanas",
            component: CampanasView,
            meta: { roles: ['ADMIN'] },
          },
          {
            path: "finalizadores",
            name: "finalizadores",
            component: FinalizadoresView,
            meta: { roles: ['ADMIN'] },
          },
        ],
      },
      {
        path:"aplicaciones/chats/chats-finalizados/detalles",
        name:"chats-detalles",
        meta: { roles: ['ADMIN'] },
        component: ProspectosDetallesView
      },
      {
        path:"aplicaciones/mis-chats/chats-finalizados/detalles",
        name:"mis-chats-detalles",
        meta: { roles: ['ADMIN','USER'] },
        component: ProspectosDetallesView
      },
      {
        path:"contactos-prospectos/prospectos/detalles",
        name:"conversaciones-detalles",
        meta: { roles: ['ADMIN','USER']},
        component: ProspectosDetallesView
      }
    ],
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: UnauthorizedPage,
  },
  {
    path: '/:catchAll(.*)', // Este es el patrón comodín para capturar todas las rutas no definidas
    name: 'NotFound',
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

// Guard global antes de cada navegación
router.beforeEach((to, from, next) => {
  const roles = to.meta.roles as string[];
  const userRole = loadToken();

  if (roles) {
    if (userRole && roles.includes(userRole)) {
      next();
    } else {
      next({ name: 'unauthorized' });
    }
  } else {
    next();
  }
});

export default router;
