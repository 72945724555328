<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Nombre
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Teléfono
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Codigo Whatsapp
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Id Whatsapp
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Creado
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(contacto, index) in contactos" :key="index">
              <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                    {{ contacto.ctnombre}}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ contacto.cttelefono }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ contacto.coidwspc}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ contacto.coidwspt}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ formatearFecha(contacto.ctfcreado) }}
              </td>
              <td class="shadow-none lh-1 text-end">
                <button  type="button"
                  @click="clickContacto(Contacto)"
                  class="bg-transparent p-0 border-0 text-paragraph fs-15 fs-md-16 fs-lg-18">
                  <i class="flaticon-view"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script >

export default {
  name: 'ContactosContent',
  data() {
    return {
      contactos: [],
      contacto: {}
    };
  },
  created() {
    this.loadContactos();
  },
  methods: {
    loadContactos() {
      this.contactos = [
        {
          "id_contacto": 1,
          "cttelefono": 1122334455,
          "id_pais": 10,
          "ctnombre": "Alejandra Ruiz",
          "ctfcreado": "2023-01-01T08:00:00Z",
          "coidwspc": "ABCD1234EFGH5678IJKL9012MNOP3456",
          "coidwspt": "1234567890abcdef"
        },
        {
          "id_contacto": 2,
          "cttelefono": 2233445566,
          "id_pais": 20,
          "ctnombre": "Carlos Sánchez",
          "ctfcreado": "2023-01-02T09:00:00Z",
          "coidwspc": "MNOP1234EFGH5678IJKL9012ABCD3456",
          "coidwspt": "fedcba0987654321"
        },
        {
          "id_contacto": 3,
          "cttelefono": 3344556677,
          "id_pais": 30,
          "ctnombre": "Laura Jiménez",
          "ctfcreado": "2023-01-03T10:00:00Z",
          "coidwspc": "IJKL1234EFGH5678MNOP9012ABCD3456",
          "coidwspt": "0123456789abcdef"
        },
        {
          "id_contacto": 4,
          "cttelefono": 4455667788,
          "id_pais": 40,
          "ctnombre": "Diego López",
          "ctfcreado": "2023-01-04T11:00:00Z",
          "coidwspc": "EFGH1234ABCD5678IJKL9012MNOP3456",
          "coidwspt": "abcdef1234567890"
        }
      ]

    },
    clickContacto(contacto) {
      this.contacto = contacto
    },
    formatearFecha(fecha) {
      return fecha.split('T')[0];
    },
  }
};
</script>