<template>
    <BreadCrumb PageTitle="Prospectos" />
    <ProspectoContent/>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
  import ProspectoContent from "../../../my-components/Prospectos/ProspectosContent.vue"

  
  export default defineComponent({
    name: "ProspectosPage",
    components: {
      BreadCrumb,
      ProspectoContent
    },
  });
  </script>