<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <button href="#" data-bs-toggle="modal" data-bs-target="#CrearUsuarioModal" type="button"
          class="default-btn border-0 transition d-block w-100 text-center position-relative text-white fs-md-15 fs-lg-16 fw-medium pt-18 pb-18 ps-15 pe-15 d-inline-block">
          Añadir nuevo usuario
          <i class="flaticon-plus lh-1 fs-13 position-relative top-1 ms-5"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Nombre
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Correo
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Rol
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Estado
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(usuario, index) in usuarios" :key="index">
              <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                    {{ usuario.nusuario }}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ usuario.uemail }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ usuario.rol }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                <span v-if="usuario.tipou === 'INACTIVO'" class="badge text-outline-danger">Inactivo</span>
                <span v-else class="badge text-outline-success">Activo</span>

              </td>
              <td class="shadow-none lh-1 text-end">
                <button type="button" @click="clickUsuario(usuario)"
                  class="bg-transparent p-0 border-0 text-paragraph fs-15 fs-md-16 fs-lg-18">
                  <i class="flaticon-view"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CrearUsuarioModal/>
</template>
  
<script >
import CrearUsuarioModal from "@/my-components/Modals/CrearUsuarioModal.vue";
export default {
  name: 'UsuariosContent',
  components:{
    CrearUsuarioModal
  },
  data() {
    return {
      usuarios: [],
      usuario: {}
    };
  },
  created() {
    this.loadUsuarios();
  },
  methods: {
    loadUsuarios() {
      this.usuarios = [
        {
          "id_usuario": 1,
          "nusuario": "Administrador",
          "uemail": "demo_admin@virtualiabot.cl",
          "clave": "ClaveSegura123",
          "rol": "ADMIN",
          "tipou": "ACTIVO"
        },
        {
          "id_usuario": 2,
          "nusuario": "Usuario",
          "uemail": "demo_user@virtualiabot.cl",
          "clave": "ClaveSegura123",
          "rol": "USER",
          "tipou": "ACTIVO"
        },
        {
          "id_usuario": 3,
          "nusuario": "David Morales",
          "uemail": "david.morales@laavanzada.cl",
          "clave": "ClaveSegura123",
          "rol": "USER",
          "tipou": "INACTIVO"
        }
      ]
    },
    clickUsuario(usuario) {
      this.usuario = usuario
    },
    formatearFecha(fecha) {
      return fecha.split('T')[0];
    },
  }
};
</script>