<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <button href="#" data-bs-toggle="modal" data-bs-target="#CrearTelefonoModal" type="button"
          class="default-btn border-0 transition d-block w-100 text-center position-relative text-white fs-md-15 fs-lg-16 fw-medium pt-18 pb-18 ps-15 pe-15 d-inline-block">
          Añadir nuevo teléfono
          <i class="flaticon-plus lh-1 fs-13 position-relative top-1 ms-5"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Nro de teléfono
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Nombre negocio
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Creado
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Estado
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Id cliente
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(telefono, index) in telefonos" :key="index">
              <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                    {{ telefono.nrotelefono }}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ telefono.nnombre }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ formatearFecha(telefono.tfcreado) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                <span v-if="telefono.estado === 'DISCONNECTED'" class="badge text-outline-danger">Desconectado</span>
                <span v-else class="badge text-outline-success">Conectado</span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ telefono.id_clienteEmpresa}}
              </td>
              <td class="shadow-none lh-1 text-end">
                <button type="button" @click="clickTelefono(telefono)"
                  class="bg-transparent p-0 border-0 text-paragraph fs-15 fs-md-16 fs-lg-18">
                  <i class="flaticon-view"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CrearTelefonoModal/>
</template>
  
<script>
import CrearTelefonoModal from "@/my-components/Modals/CrearTelefonoModal.vue"
export default {
  name: 'TelefonosContent',
  components:{
    CrearTelefonoModal
  },
  data() {
    return {
      telefonos: [],
      telefono: {}
    };
  },
  created() {
    this.loadTelefonos();
  },
  methods: {
    loadTelefonos() {
      this.telefonos = [
        {
          "id_telefono": 1,
          "id_negocio": 101,
          "nnombre":"Negocio Uno",
          "id_pais": 33,
          "nrotelefono": 1234567890,
          "tfcreado": "2023-11-29T10:00:00Z",
          "estado": "CONNECTED",
          "id_clienteEmpresa": "EmpresaA-100"
        },
        {
          "id_telefono": 2,
          "nnombre":"Negocio Dos",
          "id_negocio": 102,
          "id_pais": 34,
          "nrotelefono": 2345678901,
          "tfcreado": "2023-11-29T11:00:00Z",
          "estado": "DISCONNECTED",
          "id_clienteEmpresa": "EmpresaB-200"
        },
      ]
    },
    clickTelefono(telefono) {
      this.telefono = telefono
    },
    formatearFecha(fecha) {
      return fecha.split('T')[0];
    },
  }
};
</script>