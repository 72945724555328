import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_TelefonosContent = _resolveComponent("TelefonosContent")!
  const _component_ConexionesContent = _resolveComponent("ConexionesContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Conexiones" }),
    _createVNode(_component_TelefonosContent),
    _createVNode(_component_ConexionesContent)
  ], 64))
}