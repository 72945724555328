<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
        <div class="d-sm-flex align-items-center">
          <button href="#" data-bs-toggle="modal" data-bs-target="#CrearConexionModal" type="button"
            class="default-btn border-0 transition d-block w-100 text-center position-relative text-white fs-md-15 fs-lg-16 fw-medium pt-18 pb-18 ps-15 pe-15 d-inline-block">
            Añadir nueva conexión
            <i class="flaticon-plus lh-1 fs-13 position-relative top-1 ms-5"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                 Nro teléfono
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  Nombre campaña
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  creado
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  estado
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(conexion, index) in conexiones" :key="index">
                <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                      {{ conexion.telefono }}
                    </div>
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-primary">
                  {{ conexion.ncampana }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{formatearFecha(conexion.tcfcreado)}}
                </td>
                <td class="shadow-none lh-1 fw-medium text-muted">
                  <span v-if="conexion.tcestado === 'INACTIVO'" class="badge text-outline-danger">Inactivo</span>
                  <span v-else class="badge text-outline-success">Activo</span>
      
                </td>
                <td class="shadow-none lh-1 text-end">
                  <button  type="button" @click="clickConexion(conexion)" class="bg-transparent p-0 border-0 text-paragraph fs-15 fs-md-16 fs-lg-18">
                    <i class="flaticon-view"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <CrearConexionModal/>
  </template>
  
  <script>
  import CrearConexionModal from "@/my-components/Modals/CrearConexionModal.vue"
  export default {
    name: 'ConexionesContent',
    components:{
      CrearConexionModal
    },
    data() {
      return {
        conexiones: [],
        conexion: {}
      };
    },
    created() {
      this.loadConexiones();
    },
    methods: {
      loadConexiones() {
        let arr = [
          {
            "telefono": 1234567890,
            "ncampana":"PhoneStore",
            "id_campana":2,
            "tcfcreado":"2023-11-29T10:00:00Z",
            "tcestado":"ACTIVO"
          },
          {
            "telefono": 2345678901,
            "id_campana":3,
            "ncampana":"Campaña WOM",
            "tcfcreado":"2023-11-29T10:00:00Z",
            "tcestado":"INACTIVO"
          }
        ]
        this.conexiones = arr.filter(arr => arr.id_campana !== 1);
      },
      clickConexion(conexion){
        this.conexion = conexion
      },
      formatearFecha(fecha) {
        return fecha.split('T')[0];
      },
    }
  };
  </script>