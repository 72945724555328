<template>
    <BreadCrumb PageTitle="CHATS - Finalizados" />
    <ChatsFinalizadosContent/>
  </template>
  
  <script lang="ts">

import { defineComponent } from "vue";
  
  import BreadCrumb from "../../../../components/Common/BreadCrumb.vue"
  import ChatsFinalizadosContent from "../../../../my-components/Chats/ChatsFinalizados/ChatsFinalizadosContent.vue"
  
  export default defineComponent({
    name: "ConexionesPage",
    components: {
      BreadCrumb,
      ChatsFinalizadosContent
    },
  });
  </script>